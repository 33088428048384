.div {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 21px 70px;
}

.div > div {
  margin-right: 5px;
}
.div > div:last-of-type {
  margin-right: 0;
}

.div p {
  background-color: var(--color-bg-grey);
  padding: 3px 5px;
  font-size: 18px;
}