.bio {
  text-align: left;
  background-color: var(--color-bg-grey);
  padding: 30px var(--padding-horiz);
  font-size: 22px;
}

.bio b {
  font-weight: 600;
}

@media (max-width: 876px) {
  .bio {
    font-size: 16px;
    border-radius: 10px;
    padding: 20px var(--padding-horiz);
    margin: 0 var(--padding-horiz);
  }
}
