.intro {
  background-color: var(--color-bg-grey);
  padding: 59px var(--cs-padding-horiz) 94px;
}

.header {
  margin-bottom: 57px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header h2 {
  font-size: 32px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 0.3em;
}

.header h4 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
}

.content {
  display: flex;
}

.img {
  max-width: 58.5%;
  padding: 15px 33px;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-right: 40px;
}
img.img {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  object-fit: contain;
}

.summary {
  text-align: right;
}

.summary h3 {
  text-transform: lowercase;
  color: var(--color-drk-purple);
  font-size: 32px;
  margin: 0;
}

.summary h3::after {
  content: ".";
}

.summary p {
  font-size: 20px;
  margin-top: 0;
}

@media (max-width: 876px) {
  .img {
    max-width: 100%;
    margin-right: 0;
  }
  .summary {
    text-align: left;
  }

  .summary > *:nth-child(-n + 2) {
    margin-bottom: 30px;
  }
}

@media (max-width: 525px) {
  .header h2 {
    font-size: 24px;
  }
  .header h4 {
    font-size: 14px;
  }

  .summary h3 {
    font-size: 20px;
  }
  .summary p {
    font-size: 18px;
  }
}
