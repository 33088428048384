.sect5 {
  margin-bottom: 150px;
}

.styleGuide {
  display: flex;
  flex-direction: column;
  padding: 0 182px;
  width: 100%;
}

.logo,
.heading,
.right {
  background: #F5F5F5;
}

.top,
.head_typ,
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.typo {
  font-size: 24px;
}

.top {
  margin-bottom: 51px;
}

.heading {
  display: inline-block;
  padding: 15px 16%;
  margin-right: 102px;
}

.logo {
  align-self: flex-start;
  padding: 0 52.5px;
  margin-bottom: 47px;
}

.right {
  padding: 25px 29px 19px;
  align-self: flex-end;
}
.left {
  margin-right: 50px;
}

.pallette, .pattern{
  background: #F5F5F5;
}

.pallette {
  padding: 25px 20px 9px 17px;
  margin-right: 20px;
}

@media (max-width: 1200px) {
  .styleGuide {
    padding: 0 100px;
  }
  .typo {
    display: none;
  }
  .heading {
    margin-right: 0;
    width: 100%;
  }

  .right {
    padding: 10px;
  }


  .logo {
    padding: 0 35px;
  }
}

@media (max-width: 876px) {
  .styleGuide {
    padding: 0 var(--cs-padding-horiz);
  }
  .sect5 {
    margin-bottom: 100px;
  }
}

@media (max-width: 525px) {
  .top {
    margin-bottom: 15px;
  }
  .logo {
    margin-bottom: 0;
  }

  .pallette {
    margin: 0;
    padding: 7px 10px 10px;
  }
  .heading {
    margin-bottom: 15px;
  }

  .left {
    min-width: 55%;
    margin-right: 15px;
  }
  .right {
    background: #ffffff;
    align-self: flex-start;
    width: 100%;
    padding: 0;
  }
  .body {
    margin-bottom: 10px;
    background: #F5F5F5;
    padding: 13px 10px 11px
  }
}