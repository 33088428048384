.resume {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--padding-horiz);
  margin-bottom: 90px;
}

.resume img {
  width: 700px;
  margin-bottom: 30px;
}