.card {
  min-height: 300px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 32px;
  margin-bottom: 30px;
  margin: 0 16.4%;
}

.card hr {
  margin: 0;
  border: 3px solid #2285C5;
}

.card h5 {
  text-transform: lowercase;
  font-weight: bold;
  font-size: 24px;
  margin: 0 0 13px;
}

.content {
  min-height: 190.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14.5px 0;
  margin-bottom: 0 !important;
}

.content img {
  margin-right: 4px;
  max-width: 100%;
}

.content p {
  text-align: center;
  font-style: italic;
  font-size: 18px;
  margin: 0;
}

@media (max-width: 1027px) {
  .content img {
    display: none;
  }
}
@media (max-width: 876px) {
  .content img {
    display: block;
    max-width: 130px;
  }
  .card {
    margin: 0 var(--cs-padding-horiz);
  }
}

@media (max-width: 525px) {
  .card {
    min-height: 0;
    padding: 10px 10px 20px;
  }
  .content {
    min-height: 0;
  }
  .content img {
    max-width: 100px;
  }
  .content p {
    font-size: 12px;
  }

  .card h5 {
    font-size: 16px;
  }

  .card hr {
    border: 2.03191px solid #2285C5;
  }
}