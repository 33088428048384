.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 80px;
}

.section > div,
.section > img {
  max-width: 46%;
}

.content {
  margin-right: 30px;
}

.content h4 {
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 0;
}

.content p,
.content i {
  font-size: 20px;
  margin-top: 0;
}

@media (max-width: 1200px) {
  .content {
    margin-right: 0;
  }
  .section > div,
  .section > img,
  .section > div > img {
    max-width: 100%;
    width: 100%
  }
  .content > h4 {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (max-width: 525px) {
  .content > p,
  .content i {
    font-size: 16px;
  }
  .content > h4 {
    font-size: 20px;
    font-weight: 500;
  }
}