.header {
  padding-bottom: 52px;
  padding: 0 var(--cs-padding-horiz);
}

.img {
  background: #8cbca3;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 22px 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img img {
  width: 100%;
}

.intro {
  display: inline-block;
  text-align: right;
  min-width: 100%;
}

.intro h1,
.intro h2 {
  text-transform: uppercase;
  line-height: 55px;
  margin: 0;
}

.intro h1 {
  font-size: 64px;
  color: var(--color-blue);
  margin-top: 100px;
}

.intro h2 {
  font-size: 45px;
  color: var(--color-lgt-purple);
  margin-bottom: 65px;
}

@media (max-width: 876px) {
  .intro h1 {
    margin-top: 50px;
  }
}

@media (max-width: 725px) {
  .img {
    padding: 7px 23px;
    min-height: 0;
  }
}

@media (max-width: 525px) {
  .intro h1 {
    font-size: 38px;
    line-height: 20px;
  }

  .intro h2 {
    font-size: 28px;
  }
}
