.analysis {
  background: linear-gradient(
    90deg, 
    var(--color-bg-grey) 50%, 
    var(--color-bg-blue) 50%
  );
  padding: 54px 0 45px;
}

.analysis {
  margin-bottom: 50px;
}

.sides {
  display: flex;
  justify-content: center;
  margin-bottom: 55px;
}

.sides > div {
  width: 50%
}

.closing {
  text-align: left;
  padding: 0 var(--cs-padding-horiz);
  margin-bottom: 150px;
}

.closing p {
  font-size: 28px;
}

.closing p:first-of-type {
  margin-top: 0;
}

.closing h4 {
  font-size: 32px;
  margin-bottom: 50px;
}

.closing h4, .closing u {
  color: var(--color-green);
}

.report {
  display: flex;
  justify-content: center;
}

.report p {
  font-size: 18px;
  background: var(--color-bg-blue);
  display: inline-block;
  padding: 5px 20px;
  margin: 0;
}

@media (max-width: 876px) {
  .analysis {
    background: #ffffff;
    padding: 0;
  }
  .sides {
    flex-direction: column;
  }

  .sides > div {
    width: 100%;
    border-radius: 0;
    padding: 25px var(--cs-padding-horiz) 50px;
  }
}

@media (max-width: 525px) {
  .closing p, .closing h4, .closing u {
    font-size: 18px;
  }
  .report p,
  .report u {
    font-size: 14px;
  }
}