.div {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21px 70px;
}

.div > div {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.div > div:last-of-type {
  margin-right: 0;
}

.div > div:last-of-type p {
  position: relative;
  top: -5px;
}

.div p {
  background-color: var(--color-bg-blue);
  padding: 3px 5px;
  font-size: 18px;
}

@media (max-width: 1200px) {
  .div p {
    padding: 10px 15px;
  }
}
@media (max-width: 650px) {
  .div {
    padding: 25px 20px;
    font-size: 16px;
  }
}