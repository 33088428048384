html {
  --color-blue: rgb(33, 133, 197);
  --color-drk-blue: #2E3F6B;
  --color-pink: rgb(245, 159, 162);
  --color-green: rgb(140, 188, 163);
  --color-drk-purple: rgb(88, 87, 153);
  --color-lgt-purple: rgb(89, 114, 183);
  --color-bg-blue: #F1FAFF;
  --color-bg-grey: #EEF1F8;
  --padding-horiz: 17%;
  --cs-padding-horiz: 8.4%;
}

body {
  margin: 0;
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Jost', sans-serif;
  color: #2E3F6B;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

ol, ul {
  list-style: none;
  padding: 0;
}

button {
  border: none;
  background: none;
}

button:hover {
  cursor: pointer;
}

div {
  border-radius: 10px;
}

img {
  max-width: 100%;
}

@media (max-width: 876px) {
  html {
    --cs-padding-horiz: 15px;
    --padding-horiz: 50px;
  }
  
}

@media (max-width: 625px) {
  html {
    --padding-horiz: 20px
  }
}