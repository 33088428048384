.button {
  background: var(--color-lgt-purple);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 60px;
  color: #ffffff;
  font-size: 42px;
  font-weight: bold;
  padding: 0 92px;
  text-transform: uppercase;
}

.hPad {
  padding-left: var(--cs-padding-horiz);
  padding-right: var(--cs-padding-horiz);
}

.csIntro {
  text-align: left;
}

.csIntro h3 {
  font-size: 45px;
  margin: 0 0 30px;
  text-transform: uppercase;
}

.csIntro p,
.csIntro u,
.csIntro b,
.csIntro a,
.csIntro span {
  font-size: 28px;
}

@media (max-width: 800px) {
  

  .csIntro h3 {
    font-size: 34px;
  }
  
  .csIntro p,
  .csIntro u,
  .csIntro b,
  .csIntro a,
  .csIntro span  {
    font-size: 24px;
  }
}
@media (max-width: 525px) {
  

  .csIntro h3 {
    font-size: 28px;
  }
  
  .csIntro p,
  .csIntro u,
  .csIntro b,
  .csIntro a,
  .csIntro span  {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .button {
    font-size: 25px;
    padding: 0 56px;
  }
}