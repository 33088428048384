.div {
  background: #ffffff;
  position: relative;
  display: flex;
  padding: 33px 92px 33px 137px;
}


.div img {
  width: 100%;
}

.mobile {
  display: none;
}

@media (max-width: 876px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }

  .div {
    padding: 20px 20px 29px;
  }
}