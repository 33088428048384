.nav {
    display: flex;
    flex-direction: row;
    margin: 19px 0;
}

.link {
    color: var(--color-drk-purple);
    font-weight: 800;
    font-size: 28px;
    margin-right: 16px;
}

.active {
    color: var(--color-blue)
}

.exit {
    display: none;
}

.icons {
    display: none;
}

@media (max-width: 876px) {
    .nav {
        flex-direction: column;
        align-items: flex-end;
        margin: 15px 0 0;
    }

    .exit {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 71px;
    }

    .link {
        margin-right: 30px;
        margin-bottom: 20px;
    }
    .icons {
        display: inline-block;
        margin-right: 30px;
    }
}