.dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.dropdown h3 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 28px;
}

.dropdown h3 > svg {
  transform: translateY(5px);
}

.dropdown h3 > svg:hover {
  cursor: pointer;
}

.content {
  display: none;
  width: 100%;
}

.openContent {
  display: flex;
}

.content h4, .content li {
  font-size: 24px;
}

.content div {
  width: 50%;
}

.content h4 {
  margin: 0 0 8px;
  text-transform: lowercase;
}

.content ul {
  margin: 0;
  max-width: 321px;
  margin: auto;
}

.content li {
  margin-bottom: 8px;
  
}

.content h4::after {
  content: ".";
}

@media (max-width: 876px) {
  .content ul {
    max-width: 75%;
  }

  .dropdown h4 {
    font-size: 21px !important;
  }

  .dropdown li {
    font-size: 21px;
  }
}

@media (max-width: 525px) {
  .dropdown h3 {
    font-size: 20px;
    top: 15px;
  }
  .dropdown h4 {
    font-size: 18px !important;
  }
  .dropdown  li {
    font-size: 18px;
  }
}