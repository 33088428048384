.card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
  padding: 20px 12px;
}

.card:hover .cover {
  opacity: .9;
  top: 0;
}

.image {
  max-width: 100%;
}

.cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: -5%;
  min-height: 100%;
  min-width: 100%;
  padding: 0 20px;
  border-radius: 10px;
  background-color: black;
  opacity: 0;
  transition: all ease-in .2s;
}

.cover h2,
.cover h4 {
  color: #ffffff;
  font-weight: 500;
  margin: 0;
}

.cover h2 {
  text-transform: uppercase;
  font-size: 64px;
  line-height: 65px;
}

.cover h4 {
  font-size: 32px;
}

@media (max-width: 876px) {
  .card:hover .cover {
    opacity: 0;
    top: -5%;
  }
}