.sect {
  padding: 98px 120px;
  max-width: 100vw;
}

.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.container a {
  box-sizing: border-box;
  width: calc(50% - 19px);
  margin-bottom: 29px;
}

.container a:nth-child(odd) {
  margin-right: 38px;
}

.container a:last-child {
  margin-right: 0;
}

@media (max-width: 876px) {
  .sect {
    padding: 98px 40px;
  }
}

@media (max-width: 650px) {
  .sect {
    padding: 48px 0 0;
    margin-bottom: 80px;
  }
  .container {
    flex-direction: column;
  }

  .container a:nth-child(n) {
    margin-right: 0;
    margin-bottom: 25px;
    width: 100%;
  }
}
