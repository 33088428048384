.div {
  background: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 73px;
}
.div img {
  width: 252px;
}

.div > * {
  margin-right: 5px;
}
.div > *:last-child {
  margin-right: 0;
}

.div > div {
  max-width: 185px;
}

.div p {
  background-color: var(--color-bg-blue);
  padding: 3px 5px;
  font-size: 18px;
}