.comp1 {
  padding-top: 18px;
  background: #ffffff;
  height: 100%;
}

@media (max-width: 876px) {
  .comp1 {
    padding: 0 5px;
  }
}