.card {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 25px 22px;
  background: #ffffff;
  position: relative;
}

.card ul {
  margin: 0;
}

.card h6 {
  text-transform: lowercase;
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  text-align: center;
}

.card h6::after {
  content: "."
}

.card p, 
.card li ,
.card span {
  font-size: 20px;
}

.card > div {
  width: 50%;
}

.card > div:nth-child(-n + 2) {
  margin-bottom: 25px;
}

.details {
  display: flex;
  margin-right: 27px;
}

.details img {
  width: 140px;
  height: 140px;
  border-radius: 100%;
  margin-right: 20px;
  margin-top: 10px;
}

.details h5 {
  font-size: 24px;
  text-transform: uppercase;
  margin: 0;
  color: var(--color-drk-purple);
  /* min-width: 100%; */
  position: absolute;
  left: 150px;
}

.details ul{
  margin-top: 50px;
  position: absolute;
  left: 170px;
}

.details li {
  text-align: left;
}

.chars_str {
  position: absolute;
}

.chars_str p {
  margin: 0;
  margin-top: 25px;
}

.goals ul,
.needs ul,
.pains ul {
  list-style: inside;
}

.goals span,
.needs span,
.pains span {
  position: relative;
  left: -.5em;
} 

.needs ul,
.pains ul {
  text-align: left;
}

.goals {
  background: var(--color-bg-grey);
  border-radius: 100%;
  padding: 13px 0 25px;
  text-align: center;
  align-self: flex-end;
  transform: translateY(35px);
}

.needs,
.pains {
  background: var(--color-bg-blue);
  max-width: 49%;
  margin-top: 30px;
}

.needs {
  padding: 13px 28.5px 15px;
}

.pains {
  padding: 15px;
}

@media (max-width: 1400px) {
  .card p,
  .card li,
  .card span {
    font-size: 18px;
  }
  .details img {
    width: 100px;
    height: 100px;
  }
  .chars_str {
    top: 180px;
    font-size: 18px;
  }

  .details ul {
    top: 40px;
    left: 130px
  }

  .details h5 {
    left: 120px;
  }
}

@media (max-width: 1200px) {
  .details ul {
    transform: none;
  }
  .details img {
    width: 140px;
    height: 140px;
  }
  .chars_str p {
    margin-top: 10px;
  }
  .details ul {
    top: initial;
    left: initial
  }
  .details h5 {
    left: 170px;
  }
  .chars_str {
    top: initial;
    font-size: 20px;
  }
}

/* @media (max-width: 876px) {
  .details h5 {
    left: 170px;
  }
} */

@media (max-width: 750px) {
  .card h5 {
    font-size: 18px;
  }
  .card h6 {
    font-size: 18px;
  }
  .card p,
  .card li,
  .card span {
    font-size: 14px;
  }
  .details img {
    width: 100px;
    height: 100px;
  }
  .chars_str p {
    margin-top: 30px;
  }
  .details h5 {
    left: 140px;
  }
}

@media (max-width: 580px) {
  .card {
    padding: 19px 11px 12px;
  }
  .card h5 {
    font-size: 10.5px;
  }
  .card h6 {
    font-size: 10.5px;
  }
  .card p,
  .card li,
  .card span {
    font-size: 8.75px;
  }
  .details img {
    width: 65px;
    height: 65px;
  }
  .chars_str p {
    margin-top: 0;
    /* transform: translateY(-); */
  }
  .goals {
    transform: none;
    margin-bottom: 0;
  }
  .card > div:nth-child(-n + 2) {
    margin-bottom: 10px  ;
  }
  .details ul {
    margin-top: 20px;
    transform: translateX(-15px);
  }

  .needs {
    padding: 10px 12px 15px;
  }
  .pains {
    padding: 10px 4px;
    margin-top: 10px;
    min-height: 100%;
  }
  .card > div {
    margin: 0;
  }

  .details h5 {
    left: 80px;
  }
}