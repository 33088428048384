.header {
    display: flex;
    flex-direction: column;
}

.pattern {
    margin-bottom: 16px;
    width: 100%;
    height: 60px;
    background-image: url('../../images/header-pattern.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}

.menu {
    display: none;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 45px;
    border-radius: 0;
}

.logo {
    max-height: 80px;
}

.menuContainer {
    display: flex;
    width: 100%;
}

.logoNav {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.mobilePattern {
    display: none;
}

.icons {
    display: none;
}

@media (max-width: 876px) {
    .pattern {
        padding: 0 5.3% 0 0;
    }
    .mobilePattern {
        width: 60px;
        height: 100vh;
        padding: 30px;
        background-image: url('../../images/pattern-vert.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0;
        margin-right: 20px;
        display: block;
    }
    .menuContainer {
        flex-direction: column;
    }
    .navContainer {
        min-height: 100vh;
        z-index: 5;
    }
    .content {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100vw;
        background: #ffffff;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
    }
    .menu {
        font-size: 40px;
        display: flex;
        flex-direction: center;
        align-items: center;
        display: flex;
    }

    .logo {
        margin-top: 15px;
    }

    .icons {
        display: block;
        align-self: flex-end;
        margin-right: 30px;
    }
}
