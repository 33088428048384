.progressBar {
  background: var(--color-bg-blue);
  padding: 35px 0 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0;
}

.progressBar circle:hover {
  cursor: pointer;
}

.headings {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 8.4%;
}

.headings button {
  display: inline-block;
  line-height: 45px;
  min-width: 190px;
  margin: 0;
  font-size: 28px;
  font-weight: normal;
  color: #BABABA;
  padding: 0;
}

.headings button:hover {
  text-decoration: underline solid;
  cursor: pointer;
}

.headings button:last-of-type {
  margin-right: 0;
}

@media (max-width: 1200px) {
  .progressBar {
    background: var(--color-bg-grey);
  }
}