.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--color-bg-blue);
  padding: 30px var(--cs-padding-horiz);
  width: 100%;
  justify-content: space-between;
}

.section img {
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.1));
}

.content {
  max-width: 34%;
  margin-right: 30px;
}

.content h4 {
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 0;
}

.content p,
.content u,
.content b,
.content span {
  font-size: 20px;
  margin-top: 0;
}

.lightbox {
  margin-right: 20px;
}

@media (max-width: 1060px) {
  .content {
    max-width: 100%;
    margin-right: 0;
  }

  .content button,
  .content img {
    min-width: 100%;
    padding: 0;
  }

  .content h4 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 25px;
  }
}
