.links {
  padding: 12px 0 0;
  margin-bottom: 25px;
}

.links a {
  text-decoration: underline;
  margin-right: 25px;
  font-size: 18px;
}

.links a:last-child {
  margin-right: 0;
}

.pattern {
  background-image: url("../../images/footer-pattern.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  width: 100%;
  border-radius: 0;
}

.p {
  margin-bottom: 25px;
}

.p a {
  color: var(--color-blue);
}

.desktopP {
  display: block;
}

.mobileP {
  display: none;
}

@media (max-width: 876px) {
  .links {
    display: none;
  }
  .pattern {
    background-image: url("../../images/footer-pattern-mobile.png");
    height: 150px;
  }
  .desktopP {
    display: none;
  }

  .mobileP {
    display: block;
  }
}
