.div {
  background: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 30px ;
  width: 100%;
}

.div img {
  width: 100%;
}

.mobile {
  display: none;
}

@media (max-width: 876px) {
  .desktop {
    display: none
  }
  .mobile {
    display: block
  }
  .div {
    padding: 20px 20px 32px;
  }
}