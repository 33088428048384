.tabsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.tab {
  width: 250px;
  height: 250px;
}

.tab h3 {
  text-transform: lowercase;
  font-size: 32px;
  color: var(--color-drk-purple);
  margin: 0 0 15px;
}

.tab h3::after {
  content: "."
}

.selected {
  border: 3px solid rgba(89, 114, 182, 0.2);
  background: var(--color-bg-blue);
}

.icon {
  width: 150px;
}

@media (max-width: 650px) {
  .tab {
    display: none;
  }
  .selected {
    display: inline-block;
    border: none;
    background: none;
  }
  .tabsContainer {
    margin-bottom: 0;
  }

  .tab h3 {
    font-size: 24px;
  }

  .icon {
    width: 100px;
  }
}