.closing {
  padding: 0 16.8%;
  margin-bottom: 150px;
}

.closing h2 {
  text-transform: uppercase;
  color: var(--color-blue);
  font-size: 38px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: var(--color-bg-grey);
  /* margin: 0 10% 0; */
  padding: 40px 85px;
  min-height: 650px;
}

.content p{
  font-size: 28px;
}

.content p:last-of-type {
  margin-bottom: 35px;
}

@media (max-width: 876px) {
  .closing {
    padding: 0 var(--cs-padding-horiz);
    margin-bottom: 80px;
  }
}

@media (max-width: 650px) {
  .div {
    background: var(--color-bg-grey);
    padding: 0px 22px 15px;
  }
  .content {
    padding: 0;
    min-height: 550px;
  }

  .closing h2 {
    font-size: 32px;
  }

  .content p {
    font-size: 18px;
  }

  .content p:first-of-type {
    margin-top: 0;
  }
}