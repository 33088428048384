.compAnal {
  padding: 0 8.4% 20px;
  display: flex;
  flex-direction: column;
}

.compAnal h4 {
  text-transform: lowercase;
  font-size: 32px;
  margin-top: 0;
}

.compAnal h4::after {
  content: ".";
}

.compAnal p {
  font-size: 24px;
  text-align: left;
}

.compAnal p:last-of-type {
  margin-bottom: 63px;
}


.compAnal img {
  max-width: 100%;
  margin-top: 0%;
}

@media (max-width:1200px) {
  .compAnal img {
    margin-top: 10%;
  }
}
@media (max-width: 876px) {
  .compAnal {
    background: var(--color-bg-grey);
  }
  .compAnal img {
    max-width: 80%;
    margin: auto;
    margin-bottom: 50px;
  }
}

@media (max-width: 525px) {
  .compAnal h4 {
    font-size: 24px;
  }
  .compAnal p {
    font-size: 18px;
  }
  .compAnal img {
    max-width: 100%;
  }
}