.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--color-bg-grey);
  width: 100%;
}

.content {
  max-width: 34%;
  margin-right: 30px;
}

.content h4 {
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 0;
}

.content p,
.content span {
  font-size: 20px;
  margin-top: 0;
}

.lightbox {
  margin-right: 20px;
}

@media (max-width: 1060px) {
  .content {
    max-width: 100%;
    margin-right: 0;
  }

  .content button,
  .content img {
    min-width: 100%;
    padding: 0;
  }

  .content h4 {
    text-align: center;
    margin-bottom: 20px;
  }
}