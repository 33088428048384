/* .card {
  width: 250px;
} */

.card img{
  width: 150px;
}

.card h3 {
  font-size: 32px;
  color: var(--color-lgt-purple);
  margin: 0;
  margin-bottom: 12px;
  text-transform: lowercase;
}

.card p {
  font-size: 20px;
}

@media (max-width: 876px) {
  .card {
    width: 100%;
  }
  .card img{
    width: 125px;
  }
}