.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 80px;
}

.content {
  max-width: 34%;
  margin-right: 30px;
}

.content > h4 {
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 0;
}

.content > p,
.content > p > span {
  font-size: 20px;
  margin-top: 0;
}

.content > p:last-of-type {
  margin-bottom: 0;
}

.persona {
  filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.1));
}

@media (max-width: 1200px) {
  .content {
    max-width: 100%;
    margin-right: 0;
  }
  .content > h4 {
    text-align: center;
    margin-bottom: 10px;
  }
  .persona {
    margin-bottom: 25px;
  }
  .section {
    margin-bottom: 50px;
  }
}

@media (max-width: 525px) {
  .content > p,
  .content > p > span {
    font-size: 16px;
  }
  .content > h4 {
    font-size: 20px;
    font-weight: 500;
  }
}