.icons a {
  margin-right: 20px;
}

.icons a:last-child {
  margin-right: 0;
}

.icons img {
  max-width: 45px;
}

