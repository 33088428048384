.hobbies {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
  padding-top: 35px;;
}

.hobbies h2 {
  font-size: 42px;
  color: var(--color-blue);
  text-transform: uppercase;
}

.hobbies ol {
  display: flex;
  justify-content: space-between;
  margin-bottom: 65px;
  padding: 0 40px;
}

.hobbies li {
  max-width: 32%;
}

@media (max-width: 876px) {
  .hobbies h2 {
    font-size: 24px;
  }

  .hobbies ol {
    flex-direction: column;
    padding: 0;
  }

  .hobbies li {
    margin: 0 var(--padding-horiz) 25px;
    padding: 25px 29px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    border: 1px solid #DEDEDE;
    max-width: 100%;
  }

  .hobbies li:last-child {
    margin-right: var(--padding-horiz);
    margin-bottom: 0;
  }
}